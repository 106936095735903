import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';

interface State {
  user: string
  firstDrive: string
}

class GetUserHistory extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      user: '',
      firstDrive: ''
    }

    this.getUserHistory.bind(this)
  }

  async getUserHistory() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const drivesSnapshot = await firebase.firestore()
            .collection('drives')
            .doc(this.state.user)
            .collection('drives')
            .where('isVisible', '==', true)
            .where('isDeleted', '==', false)
            .where('isManual', '==', false)
            .orderBy('startTimeTimestampUtc', 'asc')
            .limit(1)
            .get()

        if (drivesSnapshot.docs.length > 0) {
          this.setState({firstDrive: JSON.stringify(drivesSnapshot.docs[0].data())})

          return
        }

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Get User's First Drive</Card.Title>
          <Card.Text>
            Get User's First Drive
          </Card.Text>

          <Card.Text>
            {this.state.firstDrive}
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">User</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({user: e.target.value})}
              value={this.state.user}
              placeholder="User id"
              aria-label="User id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Get'} onExecute={this.getUserHistory.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default GetUserHistory;
export const Styles = {
  CardStyle: {
    padding: '10px',
    width: '90%',
    backgroundColor: 'white',
    borderColor: 'black',
    borderWidth: 2,
    borderRadius: 4,
    borderStyle: "solid"
  }
}
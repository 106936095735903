import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';

interface State {
  tutorialA: string
  tutorialB: string
}

class CloneTutorial extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      tutorialA: '',
      tutorialB: '',
    }

    this.cloneDocument.bind(this)
  }

  async cloneDocument() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const docRef = await firebase.firestore().collection('tutorials').doc(this.state.tutorialA).get()

        if (docRef.exists) {
          const data = docRef.data()
          await firebase.firestore().collection('tutorials').doc(this.state.tutorialB).set(JSON.parse(JSON.stringify(data)))
        }

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Clone tutorial</Card.Title>
          <Card.Text>
            Copy tutorial A to tutorial B
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Tutorial A</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({tutorialA: e.target.value})}
              value={this.state.tutorialA}
              placeholder="tutorialA id"
              aria-label="tutorialA id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Tutorial B</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({tutorialB: e.target.value})}
              value={this.state.tutorialB}
              placeholder="TutorialB id"
              aria-label="TutorialB id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Clone'} onExecute={this.cloneDocument.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default CloneTutorial;
import React from 'react';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';

interface State {
  userA: string
  userB: string
}

class CloneUser extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      userA: '',
      userB: '',
    }

    this.cloneDocument.bind(this)
    this.cloneCollections.bind(this)
    this.cloneUser.bind(this)
  }

  async cloneDocument(collection: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const docRef = await firebase.firestore().collection(collection).doc(this.state.userA).get()
        console.log(`got ${collection} doc ref`)

        if (docRef.exists) {
          const data = docRef.data()
          await firebase.firestore().collection(collection).doc(this.state.userB).set(JSON.parse(JSON.stringify(data)))
        }

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  async cloneCollections(collection: string, subCollection:string) {
    if (this.state.userB !== 'Zl3DJ7R6YbcEsbYP4sCremM12nj2') {
      throw new Error('not a test user')
    }
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const docsRef = await firebase.firestore().collection(collection).doc(this.state.userA).collection(subCollection).get()
        console.log(`got ${docsRef.size} ${collection} refs`)

        if (!docsRef.empty){
          for (let doc of docsRef.docs) {
            console.log(`Copying ${collection}: ${doc.id}`);
            const data = doc.data()
            await firebase.firestore().collection(collection).doc(this.state.userB).collection(subCollection).doc(`${doc.id}`).set(JSON.parse(JSON.stringify(data)))
          }
        }
        
        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  async cloneUser() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await this.cloneCollections('drives', 'drives')
        await this.cloneCollections('savedLocations', 'locations')
        await this.cloneCollections('drivesReports', 'drivesReports')
        await this.cloneDocument('userSettings')
        await this.cloneDocument('userInfo')
        await this.cloneDocument('userSubscriptions')

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Clone user</Card.Title>
          <Card.Text>
            Copy all data from user A to user B
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">User A</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({userA: e.target.value})}
              value={this.state.userA}
              placeholder="UserA id"
              aria-label="UserA id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">User B</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({userB: e.target.value})}
              value={this.state.userB}
              placeholder="UserB id"
              aria-label="UserB id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Clone'} onExecute={this.cloneUser.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default CloneUser;
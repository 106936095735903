import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import firebase from 'firebase'
import ExecuteButton from './ExecuteButton'
import './App.css';

interface State {
  email: string
  password: string
}

class CreateUser extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      email: '',
      password: '',
    }
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Create New User</Card.Title>
          <Card.Text>
            Create a New User
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({email: e.target.value})}
              value={this.state.email}
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Password</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({password: e.target.value})}
              value={this.state.password}
              placeholder="Password"
              aria-label="Password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Create'} onExecute={async () => {
            return new Promise(async (resolve, reject) => {
              try {
                  const user = await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
                  console.log(`user: ${JSON.stringify(user)}`)
                  resolve(true)
              }
              catch(error) {
                 reject(error)
              }
            })
          }} />
        </Card.Body>
      </Card>
    )
  }
}

export default CreateUser;
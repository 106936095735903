import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';
import moment from 'moment'

interface State {
  userId: string
  data: Array<{expiresAt: Date, status: string, subscriptionType: string, google_original_purchase_token: string, apple_original_transaction_id: string, userId: string}>
}

class DownloadUserLocations extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      userId: '',
      data: []
    }

    this.downloadDocument.bind(this)
  }

  async downloadDocument() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase.firestore().collection('userSubscriptions').get()

        const result: Array<{expiresAt: Date, status: string, subscriptionType: string, google_original_purchase_token: string, apple_original_transaction_id: string, userId: string}> = []
        querySnapshot.forEach(function(doc) {
          const obj = doc.data() as {expiresAt: Date, status: string, subscriptionType: string, google_original_purchase_token: string, apple_original_transaction_id: string}
          result.push({...obj, expiresAt: new Date(obj.expiresAt), userId: doc.id})
        })

        result.sort((x1, x2) => x2.expiresAt.getTime() - x1.expiresAt.getTime())
        result.sort((x1, x2) => x2.apple_original_transaction_id ? 1 : -1)
        result.sort((x1, x2) => x2.status > x1.status ? -1 : 1)

        this.setState({data: result})   

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Download all subscriptions</Card.Title>
          {this.state.data.filter(k => k.status.toLowerCase() !== 'none').map(x => (
            <Card.Text>
              {`${x.userId}, ${moment(x.expiresAt).format('ll')}, ${x.status}, ${x.apple_original_transaction_id ? 'apple' : 'google'}, ${x.subscriptionType.startsWith('m') ? 'Monthly' : 'Annual'}`}
            </Card.Text>
          ))}


          <ExecuteButton title={'Download'} onExecute={this.downloadDocument.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default DownloadUserLocations;
import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';

interface State {
  userId: string
  data: any
}

class DownloadUserLocations extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      userId: '',
      data: undefined
    }

    this.downloadDocument.bind(this)
  }

  async downloadDocument() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase.firestore().collection('savedLocations').doc(this.state.userId).collection('locations')
        .where('isDeleted', '==', false)
        .orderBy('name', 'asc').get()

        const result: { [ind: string]: any } = {}
        querySnapshot.forEach(function(doc) {
            const savedLocation = doc.data()
            result[savedLocation.key] = savedLocation
        })

        this.setState({data: result})   

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Download all saved locations</Card.Title>
          <Card.Text>
          {JSON.stringify(this.state.data)}
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">User Id</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({userId: e.target.value})}
              value={this.state.userId}
              placeholder="userId id"
              aria-label="userId id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Download'} onExecute={this.downloadDocument.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default DownloadUserLocations;
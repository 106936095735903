import React from 'react';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import firebase from 'firebase'
import ExecuteButton from './ExecuteButton'
import './App.css';
import moment from 'moment';

interface State {
  name: string
  type: string
  expiresAt: string
  quantity: string
}

class CreatePromoCode extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      name: '',
      type: '',
      expiresAt: '2021-05-25T00:00:00.000Z',
      quantity: ''
    }
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Create New Promo Code</Card.Title>
          <Card.Text>
            Create a Promo Code
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Name (6 letters)</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({name: e.target.value})}
              value={this.state.name}
              placeholder="Promo Code Name"
              aria-label="Promo Code Name"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Type (DOUBLE or FREE_YEAR)</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({type: e.target.value})}
              value={this.state.type}
              placeholder="Promo Code Type"
              aria-label="Promo Code Type"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Expires At (format: e.g. 2020-04-20T00:00:00.000Z)</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({expiresAt: e.target.value})}
              value={this.state.expiresAt}
              placeholder="Expires At"
              aria-label="Expires At"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Quantity</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({quantity: e.target.value})}
              value={this.state.quantity}
              placeholder="Quantity"
              aria-label="Quantity"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Create'} onExecute={async () => {
            return new Promise(async (resolve, reject) => {
              try {
                  if (this.state.name.toUpperCase() !== this.state.name) {
                    throw 'name must be upper case'
                  }

                  if (moment(this.state.expiresAt).isBefore(moment().add(1, 'month'))) {
                    throw 'Expires At must be longer than a month'
                  }

                  if (this.state.type != 'DOUBLE' && this.state.type != 'FREE_YEAR') {
                    throw `Type must be 'DOUBLE' or 'FREE_YEAR'`
                  }

                  if (this.state.type == 'FREE_YEAR' && Number(this.state.quantity) > 1) {
                    throw `'FREE_YEAR' promos are limited to 1 quantity`
                  }

                  if (Number(this.state.quantity) < 1 || Number(this.state.quantity) > 100) {
                    throw 'Quantity must be between 1-100'
                  }

                  const oldPromoRef = await firebase.firestore().collection('promoCodes').doc(this.state.name).get()

                  if (oldPromoRef.exists) {
                    throw 'promo code with the same name already exist'
                  }

                  await firebase.firestore().collection('promoCodes').doc(this.state.name).set(JSON.parse(JSON.stringify({
                    name: this.state.name,
                    expiresAt: this.state.expiresAt,
                    type: this.state.type,
                    quantity: Number(this.state.quantity),
                    clicks: [],
                    usages: []
                  })))

                  resolve(true)
              }
              catch(error) {
                 reject(error)
              }
            })
          }} />
        </Card.Body>
      </Card>
    )
  }
}

export default CreatePromoCode;
import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';

interface State {
  tutorial: string
  tutorialData: any
}

class DownloadTutorial extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      tutorial: '',
      tutorialData: undefined
    }

    this.downloadDocument.bind(this)
  }

  async downloadDocument() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const docRef = await firebase.firestore().collection('tutorials').doc(this.state.tutorial).get()

        if (docRef.exists) {
          this.setState({tutorialData: docRef.data()})          
        }

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Download tutorial</Card.Title>
          <Card.Text>
          {JSON.stringify(this.state.tutorialData)}
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Tutorial</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({tutorial: e.target.value})}
              value={this.state.tutorial}
              placeholder="tutorial id"
              aria-label="tutorial id"
              aria-describedby="basic-addon1"
            />

          </InputGroup>
          <ExecuteButton title={'Download'} onExecute={this.downloadDocument.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default DownloadTutorial;
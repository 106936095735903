import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Styles} from './Styles'
import ExecuteButton from './ExecuteButton'
import firebase from 'firebase'
import './App.css';

interface State {
  user: string
}

class DeleteUserData extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      user: '',
    }

    this.deleteDocument.bind(this)
    this.deleteCollection.bind(this)
    this.deleteUserData.bind(this)
  }

  async deleteDocument(collection: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const docRef = await firebase.firestore().collection(collection).doc(this.state.user).get()
        console.log(`got ${collection} doc ref`)

        if (docRef.exists) {
          const data = docRef.data()
          await firebase.firestore().collection(collection).doc(this.state.user).delete()
        }

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  async deleteCollection(collection: string, subCollection:string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const docsRef = await firebase.firestore().collection(collection).doc(this.state.user).collection(subCollection).get()
        console.log(`got ${docsRef.size} ${collection} refs`)

        if (!docsRef.empty){
          for (let doc of docsRef.docs) {
            console.log(`Deleting ${collection}: ${doc.id}`);
            const data = doc.data()
            await firebase.firestore().collection(collection).doc(this.state.user).collection(subCollection).doc(doc.id).delete()
          }
        }
        
        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  async deleteUserData() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await this.deleteCollection('drives', 'drives')
        await this.deleteCollection('savedLocations', 'locations')
        await this.deleteCollection('drivesReports', 'drivesReports')
        await this.deleteDocument('userSettings')
        await this.deleteDocument('subscription')

        resolve(true);
      }
      catch (error) {
        reject(error)
      }
    });
  }

  render() {
    return (
      <Card style={Styles.CardStyle}>
        <Card.Body>
          <Card.Title>Delete user data</Card.Title>
          <Card.Text>
            Delete all user's data
          </Card.Text>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">User</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => this.setState({user: e.target.value})}
              value={this.state.user}
              placeholder="User id"
              aria-label="User id"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <ExecuteButton title={'Delete'} onExecute={this.deleteUserData.bind(this)} />
        </Card.Body>
      </Card>
    )
  }
}

export default DeleteUserData;